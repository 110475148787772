
<template>
  <div class="row">
    <div class="col-12">
      <h5 class="mb-3">New Court</h5>
      <div class="card">
        <div class="card-body">
          <court-form />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CourtForm from '@/components/forms/CourtForm.vue';

export default {
  components: { CourtForm },
}
</script>
